<template>
  <div class="dctr_search_filter">
    <div class="row align-items-center">
      <div class="col pl-2 pr-2">
        <label>Search</label>
        <input type="text" placeholder="Condition, procedure, doctor name..." v-model="filters.search" class="form-control" autocomplete="off">
      </div>
      <div class="col pl-2 pr-2">
        <label>Country</label>
        <select class="form-control" v-model="filters.country" :class="filters.country ? 'selected' : ''" style="text-transform:capitalize;">
          <option value="">Please Select</option>
          <option v-for="(item, index) in countryList" :key="index" :value="item.value">{{ item.name }}</option>
        </select>
      </div>
      <div class="col pl-2 pr-2">
        <label>Preferred Location/City</label>
        <input type="text" placeholder="Search" class="form-control" autocomplete="off" v-model="filters.location">
      </div>
      <div class="col pl-2 pr-2">
        <label>Select Speciality</label>
        <select class="form-control" v-model="filters.selectSpeciality" :class="filters.selectSpeciality ? 'selected' : ''">
          <option value="">Please Select</option>
          <option v-for="(item, index) in categoryList" :key="index" :value="item.filter_key">{{ item.display_name }}</option>
        </select>
      </div>
      <div class="col pl-2 pr-2">
        <label>Select Gender</label>
        <select class="form-control" v-model="filters.gender" :class="filters.gender ? 'selected' : ''">
          <option value="">Please Select</option>
          <option value="female">Female</option>
          <option value="male">Male</option>
        </select>
      </div>
    </div>
  </div>
</template>
<script>
/*eslint-disable */
import { debounce } from 'lodash';
import root from '@/_helpers/roots';
import countries from '@/services/country.json';
import axios from 'axios';
import {bus} from '@/main';
let doctorFiteredUrl = root.consultDoctorsApiUrl.link;

export default {
  name: 'doctor-filters',
  data() {
    return {
      search_term: '',
      categoryList: [],
      countryList: [],
      filters: {
        search: '',
        selectSpeciality: '',
        gender: '',
        location:'',
        country:''
      },
    }
  },
  mounted(){
    this.$store.dispatch('getDoctorsFiltersListApiFun').then((res) =>{
      this.categoryList = res.data.data.results
    })
    Object.keys(countries).map((key, index) => {
      this.countryList.push({
        name: countries[key].toLowerCase(),
        value: key.toLowerCase()
      })
    });
    this.countryList.sort((a, b) => (a.name > b.name) ? 1 : -1)
  },
  methods:{ 
    async fetchData() {
      const params = new URLSearchParams();
      if (this.filters.search) {
        params.append('search_term', this.filters.search);
      }
      if (this.filters.location) {
        params.append('location', this.filters.location);
      }
      if (this.filters.country) {
        params.append('country', this.filters.country);
      }
      if (this.filters.gender) {
        params.append('gender', this.filters.gender);
      }
      if (this.filters.selectSpeciality) {
        params.append('filter_key', this.filters.selectSpeciality);
      }
      bus.$emit('doctorsListFilterLoaderBus', true);
      try {
        await axios.get(doctorFiteredUrl, { params }).then((res) => {
          bus.$emit('doctorsListFilteredBus', res.data.data);
        })
      } catch (error) {
        console.error('Error fetching list:', error);
      }finally{
        bus.$emit('doctorsListFilterLoaderBus', false);
      }
    },
    handleChange(event) {
      const selectedValue = event.target.value;
      console.log('Selected value:', selectedValue);
      // Do something with the selected value
    },
    validateAndSearchDebounced: debounce(function (searchTerm) {
      // Validate search term (consider using a separate validation function)
      if (searchTerm.length >= 3) {
        this.$validator.validateAll().then((result) => {
          if (result) {
            // Fetch doctors if validation passes
            axios.get(doctorFiteredUrl + '?search_term=' + searchTerm)
              .then((response) => {
                this.doctorDataResults = response.data.data.data.results;
                this.nextPageUrl = response.data.data.data.next;
                this.nextSteper = response.data.data.data.next;
              })
              .catch((err) => {
                // Handle errors appropriately
              });
          }
        });
      } else if (searchTerm.length === 0) {
        // Fetch all doctors if search term is empty
        this.$store.dispatch('getDoctorsListApiFun').then((response) => {
          this.doctorDataResults = response.data.data.data.results;
          this.nextPageUrl = response.data.data.data.next;
          this.nextSteper = response.data.data.data.next;
        }).catch((err) => {
          // Handle errors appropriately
        });
      }
    }, 500), // Adjust debounce delay as needed (default 500ms)
  },
  watch: {
    filters: {
      handler() {
        this.fetchData();
      },
      deep: true,
    },
  },
}
</script>
<style lang="scss" scoped>
.dctr_search_filter {
    background:rgba(20, 26, 30, 0.6);
    padding:10px 15px;
    margin: 15px 0;
    border-radius: 4px;
    font-family:'MyriadProRegular', 'Lato', sans-serif;
    label{
      color: #afafaf;
      font-size: 18px;
      letter-spacing: 0;
      line-height: 1;
      margin-bottom: 10px;
    }
    select{color:#686868 !important;&.selected{color:#ffd400 !important;}}
  .form-control {
    background: rgba(255, 255, 255, 0.08);
    font-size: 18px;
    color: #ffd400;
    font-family: MyriadProRegular, Lato, sans-serif;
    border: 1px solid rgba(255, 255, 255, 0.1);
    width: 100%;
    line-height: 1;
    padding: 15px 12px;
    height:auto;
    box-shadow: none;
    option{color:#000000;}
    &::-webkit-input-placeholder {
      color: #686868;
    }

    &::-moz-placeholder {
      color: #686868;
    }

    &::-ms-input-placeholder {
      color: #686868;
    }

    &:focus::-webkit-input-placeholder {
      font-size: 0px;
    }

    &:focus::-moz-placeholder {
      font-size: 0px;
    }

    &:focus::-ms-input-placeholder {
      font-size: 0px;
    }
  }
}
</style>