<template>
    <div class="user_connect_request_popup_wrp">
        <div class="user_connect_request_popup_modal">
            <div class="user_connect_request_popup_modal_body text-center">
                <p class="mb-3">Your one time message has been sent with the following message:</p>
                <p class="blurb_for_services_txt mb-3">{{ message }}</p>
                <p class="mb-3">You cannot send another message till the doctor confirms your appointment.</p>
                <div class="btn-group pt-2">
                    <button type="submit" @click="closOneTimeMsgMehtod" class="btn remove_yes_no">Ok</button>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
/*eslint-disable */
import {
  bus
} from '@/main';
import toast from '@/services/toast';

export default {
  name: 'doctor-in-mail-popup',
  components: {},
  props: ['patientMsg'],
  data () {
    return {
      message:'',
    }
  },
  computed: {
  },
  mounted () {
    this.message = this.patientMsg
  },
  methods: {
    closOneTimeMsgMehtod(){
      bus.$emit('oneTimeMsgDisabledPopupBus', false);
      document.body.classList.remove('popup_open_body');
    },
  },
  watch:{
  }
}
</script>
<style lang="scss" scoped>
.user_connect_request_popup_modal {
    position: fixed;
    top: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.92);
    left: 0;
    right: 0;
    z-index: 1000;
    display: flex;
    align-items: center;

    .blurb_for_services_txt {
        color: #ffd400;
    }

    .user_connect_request_popup_modal_body {
        background: #000;
        max-width: 650px;
        width: 100%;
        margin: 0px auto;
        border-radius: 5px;
        padding: 20px 15px;
        font-family: 'MyriadProRegular', 'Lato', sans-serif;
        color: #ffffff;
        box-shadow: 3px 3px 3px rgba(255, 255, 255, 0.04), -3px -3px 3px rgba(255, 255, 255, 0.04);
        p{font-size:16px;
          line-height: 1.3;
        }
    }
    .remove_yes_no {
        font-size: 16px;
        background: #459238;
        color: #fff;
        font-family: 'MyriadProRegular', 'Lato', sans-serif;
        padding: 10px 5px;
        min-width: 70px;
        line-height: 1;
        margin-left: 10px;
        border-radius: 3px !important;
        outline: none !important;
        box-shadow: none !important;

        &:hover {
            background: #87cefa;
            color: #22292f;
        }
    }
}
</style>