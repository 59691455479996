/*eslint-disable */
const $ = window.jQuery = require('jquery');

import appointmentMakeFav from '@/components/private/doctor-make-favorite';
import axios from 'axios';
import beforeBookingMessageList from '@/components/private/before-booking-message-list';
import {bus} from '@/main';
import carousel from 'vue-owl-carousel';
import countries from '@/services/country.json'
import doctorFilters from '@/components/private/doctor-filters';
import doctorInMailPopup from '@/components/private/doctor-in-mail-popup';
import doctorViewFullInfo from '@/components/private/doctor-view-full-info';
import makeUnfavouriteDoctor from '@/components/private/make-unfavourite-doctor';
import {mapGetters} from 'vuex';
import oneTimeMsgDisabledPopup from '@/components/private/one-time-message-disabled-popup';
import root from '@/_helpers/roots';

let doctorFiteredUrl = root.consultDoctorsApiUrl.link;

export default {
  name: 'doctors-list',
  components: {
    carousel,
    'doctor-mail-popup' : doctorInMailPopup,
    'message-list':beforeBookingMessageList,
    'appointment-favourite' : appointmentMakeFav,
    'unfavourite-doctor-list' : makeUnfavouriteDoctor,
    'doctor-full-info' : doctorViewFullInfo,
    'doctor-filters' : doctorFilters,
    oneTimeMsgDisabledPopup
  },
  props: [],
  data () {
    return {
      totalCountOfFilters:[],
      doctorData:null,
      doctorDataResults:[],
      nextPageUrl:'',
      nextSteper:'',
      search_term:'',
      sliderTouchDrag:false,
      countryList:[],
      disabledFilters:'',
      apiLoader:false,
      doctorDataResults:null,
      userInfo:null,
      directSmsForPopup:false,
      beforeBookingMessageList:null,
      beforeBookingMessageListState:false,
      appointmentInfoForFav:null,
      appointmentFavModal:false,
      unFavDoctorPopupState:false,
      doctorId:'',
      doctorFullInfoState:false,
      doctorFullInfoData:null,
      oneTimeMsgDisabledPopup:false,
      oneTimeMessage:''
    }
  },
  computed: {
    ...mapGetters([
      'doctorstListGetters'
    ]),
    getAllDoctorsListFun(){
     this.doctorData = this.$store.getters.doctorstListGetters.data
     return;
    },
    SetIntialStatus() {
      if (this.doctorData) {
        this.nextPageUrl = this.doctorData.next
        this.nextSteper = this.doctorData.next
        this.doctorDataResults = this.doctorData.results
      }
      return;
    },
  },
  mounted () {
    bus.$on('doctorFullInfoStateBus', (data) => {
      this.doctorFullInfoState = data
    });
    bus.$on('unFavDoctorPopupStateBus', (data) => {
      this.unFavDoctorPopupState = data
    });
    bus.$on('appointmentFavModalBus', (data) => {
      this.appointmentFavModal = data
    });
    Object.keys(countries).map((key, index) => {
      this.countryList.push({
        name: countries[key].toLowerCase(),
        value: key.toLowerCase()
      })
    });
    this.countryList.sort((a, b) => (a.name > b.name) ? 1 : -1)
    this.getAllDoctorsListFun
    this.SetIntialStatus
    bus.$on('userUpdateStatus', (data) => {
      let user = this.doctorDataResults.find(item => item.id == data.userID);
      if(user) {
        user.is_online = data.is_online;
      }
    });
    bus.$on('directSmsForPopupBus', (data) => {
      this.directSmsForPopup = data
    })
    bus.$on('beforeBookingMessageListStateBus', (data) => {
      this.beforeBookingMessageListState = data
    })
    bus.$on('doctorsListFilteredBus', (data) => {
      this.doctorData =  data
      if (this.doctorData) {
        this.nextPageUrl = this.doctorData.next
        this.nextSteper = this.doctorData.next
        this.doctorDataResults = this.doctorData.results
      }
    });
    bus.$on('doctorsListFilterLoaderBus', (flag) => {
      this.apiLoader = flag
    });
    bus.$on('oneTimeMsgDisabledPopupBus', (data) => {
      this.oneTimeMsgDisabledPopup = data
    });
  },
  methods: {
    disabledOneTimeMsgMethod(msg){
      console.log("Test")
      this.oneTimeMessage = msg;
      this.oneTimeMsgDisabledPopup = true;
      document.body.classList.add('popup_open_body');
    },
   viewDoctorFullInfoMethod(data){
      document.body.classList.add('popup_open_body');
      this.doctorFullInfoData = data;
      this.doctorFullInfoState = true;
    },
    unFavouriteDoctorMethod(id){
      document.body.classList.add('popup_open_body');
      this.doctorId = id;
      this.unFavDoctorPopupState = true;
    },
    makeAppointmentFavouriteMethod(data){
      document.body.classList.add('popup_open_body');
      this.appointmentInfoForFav = data;
      this.appointmentFavModal = true;
    },
    beforeBookingMessageListMethod(data){
      document.body.classList.add('popup_open_body');
      this.beforeBookingMessageList = data;
      this.beforeBookingMessageListState = true;
    },
    directSmsForPopupOpenMethod(data){
      this.userInfo = data;
      this.directSmsForPopup = true;
      document.body.classList.add('popup_open_body');
    },
    getCountryFlag: function(countryCode, type) {
      if (countryCode == null) {
        countryCode = ''
      }
      let countryName = countryCode.toLowerCase()
      let country = this.countryList.filter(item => (item.value) == (countryName) || (item.name) == (countryName));
      if (country && country.length > 0) {
        country = country[0]
        // if (type == 'flag') {
        //   country = `https://567c9b4f06e54c9c9229fe11191a6432.s3.ca-central-1.amazonaws.com/media/shiny/${country.value}.png`
        // } else {
        //   country = country.name
        // }
        country = country.name
      } else {
        country = ''
      }
      return country;
    },
    showLoadMoreDataFun(url) {
      this.apiLoader = true
      axios.get(url).then((response) => {
          this.loadmoreData = response.data.data
          this.nextSteper = this.loadmoreData.data.next
          this.$set(this.doctorDataResults, 'next', this.nextSteper)
          if (this.loadmoreData) {
            for (var i = 0; i < this.loadmoreData.data.results.length; i++) {
              this.doctorDataResults.push(this.loadmoreData.data.results[i]);
              this.apiLoader = false
            }
          }
        });
    },
    handleScroll() {
      if ((document.documentElement.scrollTop + window.innerHeight) >= document.documentElement.offsetHeight) {
        if(this.nextPageUrl){
          this.showLoadMoreDataFun(this.nextPageUrl);
        }
      }
    }
  },
  watch:{
    nextSteper(old_val, new_val){
      if(new_val != ''){
        this.nextPageUrl = new_val
      }
    },
    getAllDoctorsListFun(){
      return this.$store.getters.doctorstListGetters
    },
    SetIntialStatus() {
      if (this.doctorData) {
        this.nextPageUrl = this.doctorData.next
        this.doctorDataResults = this.doctorData.results
      }
      return;
    },
  },
  created () {
    window.addEventListener('scroll', this.handleScroll);
  },
  destroyed () {
    window.removeEventListener('scroll', this.handleScroll);
  },
}